
import ItemsTable from '@/shared/components/layout/list.vue';
import {Component} from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import {ModuleProps} from '@/shared/state/template/module-props';

import SalesForm from './form.vue';
import {SaleDataRequest, salesListHeaders, salesModule, Sale} from '@/modules/sales/shared';
import TextContrast from '@/shared/components/elements/text-contrast.vue';
import IconContrast from '@/shared/components/elements/icon-contrast.vue';

@Component({
    components: {
        ItemsTable,
        SalesForm,
        TextContrast,
        IconContrast
    },
})
export default class SaleList extends ListComponent<Sale, SaleDataRequest> {
    public headers: object[] = salesListHeaders;
    public actionsTypes = salesModule.actionsTypes;
    public mutationTypes = salesModule.mutationsTypes;
    public fetchAction: string = this.actionsTypes.FETCH_DATA;
    public removeAction: string = this.actionsTypes.REMOVE_ITEM;
    public store: any = this.$store.state.salesState;
    public props: ModuleProps = salesModule.moduleProps;

    get investmentTags() {
        return this.store.investmentTagsState.dataList;
    }

    public mounted() {
        this.fetchData();
    }
}
