
import {SaleStatus, statusesModule} from '@/modules/sales/shared';
import Vue from 'vue';
import Component from 'vue-class-component';
import VueDraggable from 'vuedraggable';

@Component({
    components: {
        VueDraggable,
    },
    props: {statuses: Array},
})
export default class ChangeClosingStatuses extends Vue {
    public statuses!: SaleStatus[];
    public actionsTypes = statusesModule.actionsTypes;
    public closingStatuses: SaleStatus[] = [];
    public toDoStatuses: SaleStatus[] = [];
    public dragOptions = {
        animation: 200,
        disabled: false,
        ghostClass: 'ghost',
    };

    public onToDoChange({added, removed}: any) {
        if (!added && !removed) {
            return;
        }
        const {element} = added ? added : removed;
        this.$store.dispatch(this.actionsTypes.UPDATE_ITEM, {id: element.id, is_open: !!added});
    }

    public beforeMount() {
        this.statuses.forEach((el: any) => {
            if (!el.is_open) {
                this.closingStatuses.push(el);
            } else {
                this.toDoStatuses.push(el);
            }
        });
    }
}
