

import SaleStatusesList from '@/modules/sales/components/settings/status/list.vue';
import SaleTaskListsList from '@/modules/sales/components/settings/tasks-list/list.vue';
import Vue from 'vue';
import {Component} from 'vue-property-decorator';

@Component({
    components: {
        SaleStatusesList,
        SaleTaskListsList,
    }
})
export default class SalesStep extends Vue {
}
