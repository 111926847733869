
import ItemsTable from '@/shared/components/layout/list.vue';
import { Component } from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import { ModuleProps } from '@/shared/state/template/module-props';

import {
    SaleStatus,
    SaleStatusDataRequest,
    statusesModule,
    saleTasksListsListHeaders,
    tasksListsModule
} from '@/modules/sales/shared';
import TasksListForm from './form.vue';

@Component({
    components: {
        ItemsTable,
        TasksListForm,
    },
})
export default class SaleTaskListsList extends ListComponent<SaleStatus, SaleStatusDataRequest> {
    public headers: object[] = saleTasksListsListHeaders;
    public actionsTypes = tasksListsModule.actionsTypes;
    public mutationTypes = tasksListsModule.mutationsTypes;
    public fetchAction: string = this.actionsTypes.FETCH_DATA;
    public removeAction: string = this.actionsTypes.REMOVE_ITEM;
    public store: any = this.$store.state.salesState.tasksListsState;
    public props: ModuleProps = tasksListsModule.moduleProps;
}
