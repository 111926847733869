
import Vue from 'vue';
import TicketCategoriesList from '@/modules/tickets/components/settings/categories/list.vue';
import TicketPrioritiesList from '@/modules/tickets/components/settings/priorities/list.vue';
import TicketStatusesList from '@/modules/tickets/components/settings/status/list.vue';
import TicketTagsList from '@/modules/tickets/components/settings/tags/list.vue';
import {Component} from 'vue-property-decorator';

@Component({
    components: {
        TicketCategoriesList,
        TicketPrioritiesList,
        TicketStatusesList,
        TicketTagsList,
    },
})
export default class TicketsStep extends Vue {

}

