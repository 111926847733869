
import { httpClient } from '@/shared/services';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import InvestmentsStep from './steps/investments.vue';
import SalesStep from './steps/sales.vue';
import TicketsStep from './steps/tickets.vue';
import { mutationTypes as authMutations } from '@/modules/auth/shared/state';
import { RawLocation } from 'vue-router';
import TestModeStep from './steps/test-mode.vue';

@Component({
    components: {
        InvestmentsStep,
        TicketsStep,
        SalesStep,
        TestModeStep,
    },
})
export default class FirstSetup extends Vue {
    public step = 1;
    public loading = false;
    public dialogGenerator = false;
    public switchTestModeAfterwards = false;
    public testModeSwitching = false;

    public nextStep() {
        if (this.step < 4) {
            this.step++;
        } else {
            this.endConfiguration();
        }
    }

    public finishSetup(route: RawLocation) {
        this.loading = true;
        httpClient
            .put('/api/v1/user', {
                first_login: false,
            })
            .then((response) => {
                if (response.data) {
                    this.loading = false;
                    this.$store.commit(authMutations.CHECK_USER_SUCCESS, response.data);

                    this.$router.push(route);

                    if (this.switchTestModeAfterwards) {
                        this.$store.state.testMode = 'active';
                    }
                }
            });
    }

    public endConfiguration() {
        if (this.switchTestModeAfterwards) {
            httpClient.post('/api/v1/admin/client/switch-test-mode').then((res) => {
                this.dialogGenerator = true;
            });
        } else {
            this.dialogGenerator = true;
        }
    }
}
