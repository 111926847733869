
import { Component, Watch } from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import { statusesModule, SaleTasksListDataRequest, tasksListsModule, SaleStatus } from '@/modules/sales/shared';
import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';
import { investmentSubjectTypesModule } from '@/modules/investments/shared/state/submodules';
import SubjectTypeForm from '@/modules/investments/components/settings/subject/form.vue';
import { SaleStatusForm } from '../..';
import { InvestmentSubjectType } from '@/modules/investments/shared/models/subjects/investment-subject-type';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {
        FormActions,
        AutoCompleteWithAddNew,
    },
})
export default class TasksListForm extends FormComponent<SaleTasksListDataRequest> {
    public actionsTypes = tasksListsModule.actionsTypes;
    public store: any = this.$store.state.salesState.tasksListsState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new SaleTasksListDataRequest();

    public subjectTypesState = this.$store.state.investmentsState.subjectTypesState;
    public investmentSubjectTypesModule = investmentSubjectTypesModule;
    public investmentSubjectTypesActions = investmentSubjectTypesModule.actionsTypes;
    public investmentSubjectTypesFetchAction = this.investmentSubjectTypesActions.FETCH_DATA;
    public SubjectTypeForm = SubjectTypeForm;

    public saleStatusesState = this.$store.state.salesState.statusesState;
    public statusesModule = statusesModule;
    public statusesActions = statusesModule.actionsTypes;
    public statusesFetchAction = this.statusesActions.FETCH_DATA;
    public StatusForm = SaleStatusForm;

    public created() {
        //
    }

    get formTitle(): string {
        return this.edit ? 'Edytuj listę zadań' : 'Dodaj listę zadań';
    }

    get subjectTypes() {
        return this.subjectTypesState.data;
    }

    get subjectTypesLoading() {
        return this.subjectTypesState.loading;
    }

    get saleStatuses() {
        return this.saleStatusesState.data;
    }

    get saleStatusesLoading() {
        return this.saleStatusesState.loading;
    }

    @Watch('itemData.investment_subject_type_id')
    public onStatusChange(value: number) {
        if (value) {
            this.changeName();
        }
    }

    @Watch('itemData.sale_status_id')
    public onSubjectTypeChange(value: number) {
        if (value) {
            this.changeName();
        }
    }

    public changeName() {
        if (this.itemData.investment_subject_type_id && this.itemData.sale_status_id && !this.itemData.name) {
            const type = this.subjectTypes.find(
                (item: InvestmentSubjectType) => item.id === this.itemData.investment_subject_type_id,
            );
            const status = this.saleStatuses.find((item: SaleStatus) => item.id === this.itemData.sale_status_id);
            if (type !== undefined && status !== undefined) {
                this.$set(this.itemData, 'name', `${type.name} - ${status.name}`);
            } else {
                this.$set(this.itemData, 'name', ``);
            }
        }
    }

    public initForm() {
        this.form = this.$refs.createSaleTasksListForm;
    }
}
