
import {Component} from 'vue-property-decorator';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import {statusesModule, SaleStatusDataRequest} from '@/modules/sales/shared';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {FormActions},
})
export default class StatusForm extends FormComponent<SaleStatusDataRequest> {
    public actionsTypes = statusesModule.actionsTypes;
    public store: any = this.$store.state.salesState.statusesState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new SaleStatusDataRequest();

    public created() {
        //
    }

    get formTitle(): string {
        return this.edit ? 'Edytuj status' : 'Dodaj status';
    }

    public initForm() {
        this.form = this.$refs.createSaleStatusForm;
         }
}
