
    import { Component, Watch } from 'vue-property-decorator';
    import FormComponent from '@/shared/components/layout/form/form-component';
    import FormActions from '@/shared/components/layout/form/form-actions.vue';

    import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';
    import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
    import InvestmentTagChip from '@/modules/investments/components/settings/tags/tag.vue';
    import TextContrast from '@/shared/components/elements/text-contrast.vue';
    import IconContrast from '@/shared/components/elements/icon-contrast.vue';

    import { SaleDataRequest, salesModule, SaleStatus, statusesModule } from '@/modules/sales/shared';
    import { SaleStatusForm } from '@/modules/sales/components';
    import DatePicker from '@/shared/components/elements/date-picker.vue';
    import { actionsTypes } from '@/modules/admin/shared/state';
    import { User } from '@/modules/admin/shared/models/user';
    import Wysiwyg from '@/shared/components/documents/wysiwyg.vue';
    import SubjectCard from '@/modules/investments/components/subjects/subject-card.vue';
    import SelectTree from '@/shared/components/elements/select-tree.vue';
    import CardPicker from '@/shared/components/layout/form/card-picker.vue';
    import { leadSources } from '@/shared/config/sales';
    import { investmentSubjectOperationsModule } from '@/modules/investments/shared/state/submodules';
    import PhoneInput from '@/shared/components/elements/phone-input.vue';

    @Component({
        components: {
            Wysiwyg,
            DatePicker,
            SelectTree,
            CardPicker,
            SubjectCard,
            FormActions,
            AutoCompleteWithAddNew,
            ActionConfirmDialog,
            InvestmentTagChip,
            TextContrast,
            IconContrast,
            PhoneInput,
        },
    })
    export default class SalesForm extends FormComponent<SaleDataRequest> {
        public leadSources = leadSources;
        public actionsTypes = salesModule.actionsTypes;
        public subjectTypes = investmentSubjectOperationsModule.actionsTypes;
        public store: any = this.$store.state.salesState;
        public storeAction = this.actionsTypes.STORE_ITEM;
        public updateAction = this.actionsTypes.UPDATE_ITEM;
        public requestData = new SaleDataRequest();
        public props = salesModule.moduleProps;

        public StatusForm = SaleStatusForm;

        public statusesModule = statusesModule;

        public statusesState = this.$store.state.salesState.statusesState;
        public prioritiesState = this.$store.state.salesState.prioritiesState;
        public tagsState = this.$store.state.salesState.tagsState;
        public categoriesState = this.$store.state.salesState.categoriesState;
        public subjectsStore = this.$store.state.investmentsState.subjectOperationsState;
        public subjectFilters: any = {};

        get statusesLoading() {
            return this.statusesState.loading;
        }

        get statuses() {
            return this.statusesState.data;
        }

        get investments() {
            return this.$store.state.investmentsState.dataList;
        }

        get subjects() {
            return this.$store.state.investmentsState.dataList;
        }

        get usersLoading() {
            return this.$store.state.adminState.loadingList;
        }

        get currentUser() {
            return this.$store.state.authState.user;
        }

        get users() {
            return [
                this.currentUser,
                ...this.$store.state.adminState.users.filter((user: User) => user.id !== this.currentUser.id),
            ];
        }

        public created() {
            this.$store.dispatch(actionsTypes.FETCH_USER_DATA, { simple: true });
        }

        get formTitle(): string {
            return this.edit ? 'Edytuj lead sprzedażowy' : 'Dodaj lead sprzedażowy';
        }

        public initForm() {
            this.form = this.$refs.createSaleForm;
        }

        public initFocus() {
            const status = this.statuses.find((e: SaleStatus) => e.default);

            if (status) {
                this.$set(this.itemData, 'status', status);
                this.$set(this.itemData, 'sale_status_id', status.id);
            }
        }

        public isString(value: any) {
            return typeof value === 'string';
        }

        @Watch('itemData.investment_id')
        public onInvestmentChange(newVal: number) {
            if (newVal) {
                this.subjectFilters = { investment_id: [newVal] };
            }
        }
    }
