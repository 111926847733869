

import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import InvestmentTagsList from '@/modules/investments/components/settings/tags/list.vue';
import ObjectTypeSettingsList from '@/modules/investments/components/settings/objects/list.vue';
import SubjectPropertiesTypesList from '@/modules/investments/components/settings/subject-properties/list.vue';
import SubjectTypesList from '@/modules/investments/components/settings/subject/list.vue';
import SubjectRoomTypesList from '@/modules/investments/components/settings/rooms/list.vue';

@Component({
    components: {
        InvestmentTagsList,
        ObjectTypeSettingsList,
        SubjectPropertiesTypesList,
        SubjectTypesList,
        SubjectRoomTypesList,
    }
})
export default class InvestmentsStep extends Vue {

}

