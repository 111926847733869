
import ItemsTable from '@/shared/components/layout/list.vue';
import { Component } from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import { ModuleProps } from '@/shared/state/template/module-props';

import { SaleStatus, SaleStatusDataRequest, statusesModule, saleStatusesListHeaders } from '@/modules/sales/shared';
import StatusForm from './form.vue';
import { httpClient } from '@/shared/services';
import ChangeClosingStatuses from './change-closing-statues.vue';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';

@Component({
    components: {
        ItemsTable,
        StatusForm,
        ChangeClosingStatuses,
        TooltipBtn,
    },
})
export default class SaleStatusesList extends ListComponent<SaleStatus, SaleStatusDataRequest> {
    public headers: object[] = saleStatusesListHeaders;
    public actionsTypes = statusesModule.actionsTypes;
    public mutationTypes = statusesModule.mutationsTypes;
    public fetchAction: string = this.actionsTypes.FETCH_DATA;
    public removeAction: string = this.actionsTypes.REMOVE_ITEM;
    public store: any = this.$store.state.salesState.statusesState;
    public props: ModuleProps = statusesModule.moduleProps;
    public loadingSetNewDefaultPriority = false;
    public changeClosingDialog = false;

    public mounted() {
        this.fetchData();
    }

    public setNewDefaultPriority(id: number) {
        this.loadingSetNewDefaultPriority = true;
        httpClient
            .patch(`api/v1/sales/statuses/${id}`, {
                default: true,
            })
            .then(() => {
                this.fetchData();
                this.$store.commit('SHOW_SNACKBAR', { type: 'success', text: 'Zmienionio domyślny priorytet' });
            })
            .catch(() => {
                this.$store.commit('SHOW_SNACKBAR', { type: 'error', text: 'Coś poszło nie tak' });
                this.loadingSetNewDefaultPriority = false;
            });
    }

    public goToLeads() {
        this.store.relatedDialog = false;
        this.$router.push({name: 'sales-list'});
    }
}
